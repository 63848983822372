import React from "react";
import {
    LineChartOutlined,
    WechatOutlined,
    TeamOutlined
} from "@ant-design/icons";

const data = {
    menuItems : [
        {
            title : "Dashboard",
            link  : "/kelola/dashboard",
            icon  : <LineChartOutlined style={{fontSize : 18}} />
        }, {
            title : "User Management",
            link  : "/kelola/user",
            icon  : <TeamOutlined style={{fontSize : 18}} />
        }, {
            title : "User Feedback",
            link  : "/kelola/feedback",
            icon  : <WechatOutlined style={{fontSize : 18}} />
        }
    ]
}

export default data