import React from 'react';

interface Pages {
    path      : string,
    isPublic  : boolean,
    component : React.LazyExoticComponent<React.FC>
}

let pages : Pages[] = [
    {
        path      : "/kelola/dashboard",
        isPublic  : false,
        component : React.lazy(() => import('../pages/dashboard')),
    }, {
        path      : "/kelola/user",
        isPublic  : false,
        component : React.lazy(() => import('../pages/user')),
    }, {
        path      : "/kelola/feedback",
        isPublic  : false,
        component : React.lazy(() => import('../pages/feedback')),
    }, {
        path      : "/",
        isPublic  : true,
        component : React.lazy(() => import('../pages/login')),
    }, {
        path      : "/login",
        isPublic  : true,
        component : React.lazy(() => import('../pages/login')),
    }
    ];

export default pages;